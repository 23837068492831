import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "lPage" }
const _hoisted_2 = { class: "lAuth_row" }
const _hoisted_3 = { class: "lAuth_col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_as_header_mobile = _resolveComponent("as-header-mobile")!
  const _component_social_networks = _resolveComponent("social-networks", true)!
  const _component_mq_responsive = _resolveComponent("mq-responsive")!
  const _component_as_breadcrumb = _resolveComponent("as-breadcrumb")!
  const _component_as_header_main = _resolveComponent("as-header-main")!
  const _component_as_menu = _resolveComponent("as-menu")!
  const _component_as_spacer = _resolveComponent("as-spacer")!
  const _component_as_footer = _resolveComponent("as-footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_mq_responsive, {
      class: "lUserEdit -edit -mobile",
      target: "mobile-tablet"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_as_header_mobile, {
          title: _ctx.$t('linkSocialNetworks'),
          back: "",
          nav: false
        }, null, 8, ["title"]),
        _createVNode(_component_social_networks)
      ]),
      _: 1
    }),
    _createVNode(_component_mq_responsive, {
      class: "lAuth -edit bg-blue",
      target: "desktop"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_as_header_main, null, {
          breadcrumb: _withCtx(() => [
            _createVNode(_component_as_breadcrumb, { title: _ctx.title }, null, 8, ["title"])
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_as_menu, {
              menu: _ctx.menu,
              type: "sidebar"
            }, null, 8, ["menu"])
          ]),
          _createVNode(_component_social_networks, { "show-title": "" })
        ]),
        _createVNode(_component_as_spacer),
        _createVNode(_component_as_footer)
      ]),
      _: 1
    })
  ]))
}